import React from 'react';

const ServicePriceNote = () => {
    return (
        <div>
            <b className='gradient_text_box'>Key Notes for Clients :</b> <br />
            <b>Pricing is flexible and project-specific:</b> Final quotes depend on the scope, timeline, and client requirements. <br />
            <b>Packages Available:</b>  Discounts for bundled services (e.g., maintenance + speed optimization). <br />
            <b>Free Consultation:</b>  I offer a free consultation to help you understand your needs and give you a personalized quote.<br />
            <b>NB:</b> Service plans, prices, and values may change based on time and market demand.

        </div>
    );
};

export default ServicePriceNote;