import React from 'react';
import { Link } from 'react-router-dom';

const NaveBar = ({ children }) => {
  return (
    <div className="drawer drawer-mobile bg-[#ecf0f3]">
      <input id="myMainSideBarMenu" aria-label="My Main SideBar Menu" type="checkbox" className="drawer-toggle" />

      <div className="drawer-content">
        {/* <!-- Page content here -->  */}
        <label htmlFor="myMainSideBarMenu" className="btn rounded-none w-full drawer-button lg:hidden"> Menu </label>
        {children}
      </div>

      <div className="drawer-side">
        <label htmlFor="myMainSideBarMenu" className="drawer-overlay"></label>
        <ul className="menu p-4 overflow-y-auto w-60 text-base-content">
          {/* <!-- Sidebar content here -->  */}
          <li><Link className='kib_inside_effect text-[#8a92a5] mt-6' to='/'>Home</Link></li>
          <li><Link className='kib_inside_effect text-[#8a92a5] mt-6' to='/AllServices'> All Services </Link></li>
          <li><Link className='kib_inside_effect text-[#8a92a5] mt-6' to='/Contact'>Contact</Link></li>
          <li><Link className='kib_inside_effect text-[#8a92a5] mt-6' to='/About'> About </Link></li>
          <li><Link className='kib_inside_effect text-[#8a92a5] mt-6' to='/MyPortfolio'> All Projects </Link></li>
          <li><Link className='kib_inside_effect text-[#8a92a5] mt-6' to='/MyTools'> Tools </Link></li>
          <li><Link className='kib_inside_effect text-[#8a92a5] mt-6' to='/Blogs'> Blogs </Link></li>
        </ul>
      </div>
      {/* google-site-verification=REH85K6dm995YkGnc83vdJv61fIK_fT9bClrBiqDxrE */}
    </div>
  );
};

export default NaveBar;